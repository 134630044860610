import React from "react";
import cx from "classnames";
import loadable from "@loadable/component";

import { ModuleWrapper, useModuleContext } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { SanityImage } from "@components/sanity/sanityImage";
import LotiePlayer from "@components/media/lottiePlayer";

const VideoComponent = loadable(() => import("react-player/lazy"));

const InnerComponent = ({
  image,
  text,
  layout,
  isVideo,
  videoFile,
  isLottie,
  lottie,
}) => {
  const { inView } = useModuleContext();
  return (
    <div className="px-gutter grid gap-y-10 lg:grid-cols-2 lg:gap-x-16 lg:items-center">
      {!isVideo && !isLottie && (
        <SanityImage
          image={image}
          className={cx("md:row-start-1 rounded-lg ", {
            "md:col-start-2": layout === "right",
            "md:col-start-1": layout === "left",
          })}
        />
      )}
      {isVideo && videoFile && (
        <div
          className={cx(
            "md:row-start-1 aspect-w-1 aspect-h-1 w-full relative z-10 rounded-lg overflow-hidden",
            {
              "md:col-start-2": layout === "right",
              "md:col-start-1": layout === "left",
            }
          )}
        >
          {inView && (
            <VideoComponent
              url={videoFile}
              loop
              playing
              muted
              playsinline
              width="100%"
              height="100%"
              className="bg-video "
            />
          )}
        </div>
      )}
      {isLottie && lottie?.asset && (
        <div
          className={cx("md:row-start-1", {
            "md:col-start-2": layout === "right",
            "md:col-start-1": layout === "left",
          })}
        >
          <LotiePlayer
            url={lottie.asset.url}
            className="ml-auto mt-8"
            playing={true}
            autoplay={false}
          />
        </div>
      )}
      <div className="md:row-start-1">
        {text && <PortableTextBlock text={text} />}
      </div>
    </div>
  );
};

const ImageText = ({ config, ...props }) => {
  return (
    <ModuleWrapper {...config}>
      <InnerComponent {...props} />
    </ModuleWrapper>
  );
};

export default ImageText;
